let Typewriter = function(el, textArray, period) {
    this.textArray = textArray;
    this.el = el;
    this.loopNum = 0;
    this.period = parseInt(period, 10) || 2000;
    this.txt = '';
    this.tick();
    this.isDeleting = false;
};

Typewriter.prototype.tick = function() {
    let i = this.loopNum % this.textArray.length,
        fullTxt = this.textArray[i];

    if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
    }

    this.el.innerHTML = '<span class="wrap">'+this.txt+'</span>';

    let that = this,
        delta = 300 - Math.random() * 100;

    if (this.isDeleting) { delta /= 2; }

    if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
    } else if (this.isDeleting && this.txt === '') {
        this.isDeleting = false;
        this.loopNum++;
        delta = 500;
    }

    setTimeout(function() {
        that.tick();
    }, delta);
};

window.onload = function() {
    let elements = document.getElementsByClassName('typewriter');

    for (let i = 0; i < elements.length; i++) {
        let textArray = elements[i].getAttribute('data-array'),
            period = elements[i].getAttribute('data-time');

        if (textArray) {
            new Typewriter(elements[i], JSON.parse(textArray), period);
        }
    }

    let css = document.createElement("style");

    css.type = "text/css";
    css.innerHTML = ".txt-rotate > .wrap { border-right: 0.08em solid #666 }";
    document.body.appendChild(css);
};
